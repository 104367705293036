@import "~antd/dist/antd.css";

body {
  color: #37383c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-variant-ligatures: none;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 1.4;
  margin: 0;
  padding: 0;
}
#root {
  height: 100vh;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
a,
a:hover {
  text-decoration: none;
}
a {
  color: #3b6ecc;
  transition: color 0.2s linear;
  cursor: pointer;
}

.uploader {
  width: 128px;
  height: 128px;
}

.uploader > div {
  width: 128px !important;
  height: 128px !important;
}
